"use server";
import { getBrand, getCoinsSummary } from "../../apis/server_apis";
import { Header } from "~/types";
import { getCookie } from "vinxi/http";
import { redirect } from "@solidjs/router";
import { VoucherProduct } from "../../types/search";
import { getV3GiftingDataStorageKey } from "../../../components/gifting/brand-collection-landing/gift_box_v3_landing_route_data";
import { APIError } from "~/utils/fetch";
import { getRequestEvent } from "solid-js/web";

export type GiftBoxV3OrderRouteData = {
  coinsCount: number;
  brand: VoucherProduct;
  logoUrl?: string;
};

export type GiftBoxV3OrderRouteDataProps = {
  giftingKey: string;
  brandKey: string;
};

export async function getGiftBoxV3OrderRouteData(
  props: GiftBoxV3OrderRouteDataProps
) {
  let sessionId = getCookie(
    getV3GiftingDataStorageKey(props.giftingKey, "sessionId")
  );
  if (!sessionId) {
    throw redirect("/gift-box/m/" + props.giftingKey);
  }

  let coinsCount$P = getCoinsSummary(
    {
      [Header.SessionId]: sessionId,
    },
    {
      isUnauthenticated: true,
      throwAuthError: true,
    }
  );

  let getVoucherProduct$P = getBrand(
    props.brandKey,
    {
      [Header.SessionId]: sessionId,
    },
    {
      isUnauthenticated: true,
      throwAuthError: true,
    }
  );

  try {
    const resolved = await Promise.all([coinsCount$P, getVoucherProduct$P]);

    let coinsSummary = resolved[0];
    let brand = resolved[1];

    let logoUrl: string | undefined = getRequestEvent()?.locals.clientLogoUrl;

    return {
      coinsCount: coinsSummary.totalAvailable,
      brand: brand,
      logoUrl: logoUrl,
    };
  } catch (error) {
    if (error instanceof APIError) {
      if (error.code === 401) {
        throw redirect("/gift-box/m/" + props.giftingKey);
      }
    }
  }
}
